import React from 'react'
export enum Role {
  Readers = 0,
  Customer = 1,
  Controllers = 2,
  Administrators = 3,
}
export enum AADRole {
  '63374caf-c649-498e-afd8-930a1e6d7ee0' = 2, // RBAC_ovrig-anställd
  '3524073e-c370-40c9-a2dc-6907fef27625' = 2, // RBAC_energi_controller
  'ba5eb121-781b-46f5-9f36-007b073e1013' = 2, // RBAC_forvaltare
  '1b8cd9ba-892e-4b2d-af77-80a07fc81894' = 2, // RBAC_kundansvarig
  '53a720ca-4000-42d0-a3de-5aad1a7aa38d' = 2, // RBAC_Front-office
  '51098455-b595-44f6-997c-de6bd920d26e' = 2, // RBAC_platschef
}