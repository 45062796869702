import axios, {AxiosRequestConfig, AxiosResponse, AxiosError, AxiosInstance, Canceler} from 'axios'
import {useNavigate} from 'react-router-dom'
const $baseURL: string = "https://api.saveup.se/api" // process.env.BASE_URL || '/api' // 

const httpHeaders = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
}

const config: AxiosRequestConfig = {
  baseURL: $baseURL,
  headers: httpHeaders,
  timeout: 10000,
  //withCredentials: true,
  responseType: 'json',
  maxContentLength: 2000,
  maxBodyLength: 2000,
  maxRedirects: 5,
  cancelToken: new axios.CancelToken((cancel: Canceler) => []),
}

export const handleResponse = (response: AxiosResponse) => {
  return response?.data
}

export const handleError = (error: AxiosError) => {
  if (error.response) {
    return error.response.status
  } else {
    return error.message
  }
}
export const useToken = () => {
  return JSON.parse(localStorage.getItem('auth')!).token || ''
}

const saveupClient: AxiosInstance = axios.create(config)

saveupClient.interceptors.request.use(
  (config) => {
    if (config.headers !== undefined) {
      const userAuth = JSON.parse(localStorage.getItem('auth')!)
      if (userAuth && userAuth.token) config.headers.authorization = `Bearer ${userAuth.token}`
      else delete config.headers.authorization
    }
    return config
  },
  (error) => {
    //useHistory.push('/error/501')
    console.log(error)
    return Promise.reject(error)
  }
)

saveupClient.interceptors.response.use(
  (response) => response,
  (error) => {    
    if(!localStorage.getItem('auth') && error.response?.status === 401) {
      return Promise.reject(error)
    }
    else if (error.response?.status === 401) {
      localStorage.removeItem('auth')
      const navigate = useNavigate()
      navigate('/login')

      return Promise.reject(error)
    }
    else return Promise.reject(error)
  }
)
export default saveupClient
